<template>
  <div class="main">
    <section class="price-block">
      <h1 class="tittle">{{ $t('package.getData') }}</h1>
      <div class="price-content">
        <div class="switch-group">
          <span class="month">{{ $t('package.monthly') }}</span>
          <a-switch v-model="discount" class="switch"></a-switch>
          <span class="year">{{ $t('package.annual') }}</span>
          <span class="tip">{{ `（${$t('package.savemoney')}）` }}</span>
        </div>
        <!-- <div class="line"></div> -->
        <div class="package-group">
          <div class="package-item" v-for="(item, index) in packages" :key="index" :class="item.name === 'ENTERPRISE' ? 'active' : ''">
            <img v-if="item.name === 'ENTERPRISE'" class="recommended" src="../assets/images/price/recommended.png" />
            <div class="package-head">
              <h2 class="package-name">{{ $t(`package.${item.name}`) }}</h2>
              <span class="price">
                <div class="currency-icon">{{ currencyMap[currency] }}</div>
                <h2 class="price-num">{{ discount ? item[priceLocaleMap[locale]][1] : item[priceLocaleMap[locale]][0] }}</h2>
                <span class="unit-time">/{{ $t('time.month', { num: '' }) }}</span>
              </span>
              <h3 class="save-money" v-if="item[priceLocaleMap[locale]][0] - item[priceLocaleMap[locale]][1] && discount">{{ $t('package.saveMonth', { num: currencyMap[currency] + (item[priceLocaleMap[locale]][0] - item[priceLocaleMap[locale]][1]) }) }}</h3>
              <router-link :to="{ name: 'order', query: { package: item.name, duration: discount ? 12 : 3 } }" class="buy-btn">{{
                item.name == 'FREE' ? $t('package.freeTrial') : $t('package.subscribe')
              }}</router-link>
              <h3 class="tip">{{ $t(`package.${item.name}_tip`) }}</h3>
            </div>
            <div class="package-foot">
              <div class="package-content">
                <div class="content-item">
                  <!-- <div class="item-value" v-if="item.name == 'FREE'">1,000</div>
                  <div class="item-value" v-if="item.name == 'BASIC'">300,000</div>
                  <div class="item-value" v-if="item.name == 'PROFESSION'">900,000</div>
                  <div class="item-value" v-if="item.name == 'ENTERPRISE'">4,000,000</div>
                  <div class="item-value" v-if="item.name == 'ULTIMATE'">8,000,000</div> -->
                  <h3 class="item-value">{{ item.request }}</h3>
                  <h4 class="item-tittle">{{ $t('package.callsPerMonth') }}</h4>
                </div>
                <div class="content-item">
                  <h3 class="item-value">{{ item.qps }}</h3>
                  <h4 class="item-tittle">{{ $t('package.qps', { num: item.qps }) }}</h4>
                </div>
                <div class="content-item">
                  <h3 class="item-value" v-if="item.ws">{{ item.ws }}</h3>
                  <h3 class="item-value" v-else><img src="../assets/images/price/false.png" /></h3>
                  <h4 class="item-tittle">{{ $t('package.ws') }}</h4>
                </div>
                <div class="content-item">
                  <h3 class="item-value" v-if="item.isSupportRest"><img src="../assets/images/price/true.png" /></h3>
                  <h3 class="item-value" v-else><img src="../assets/images/price/false.png" /></h3>
                  <h4 class="item-tittle">Rest Endpoint</h4>
                </div>
                <div class="content-item">
                  <h3 class="item-value"><img src="../assets/images/price/true.png" /></h3>
                  <h4 class="item-tittle">{{ $t('package.emailSupport') }}</h4>
                </div>
                <div class="content-item">
                  <h3 class="item-value" v-if="item.wh"><img src="../assets/images/price/true.png" /></h3>
                  <h3 class="item-value" v-else><img src="../assets/images/price/false.png" /></h3>
                  <h4 class="item-tittle">Webhook Support (Beta)</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="custom-block">
      <div class="left-blcok">
        <div class="left-box">
          <h2 class="tittle">{{ $t('package.customFunctions') }}</h2>
          <span class="tip">{{ $t('package.deepCooperation') }}</span>
          <a class="contact" :href="locale === 'zh_CN' ? `/contactus` : 'https://t.me/Blockcc_bc'" target="_blank">{{ $t('package.contactus') }}</a>
        </div>
      </div>
      <div class="right-block">
        <div class="contact-group-left">
          <div class="contact-item">
            <img class="item-icon" src="../assets/images/price/duigou-icon.png" />
            <h3 class="item-text">{{ $t('package.co1') }}</h3>
          </div>
          <div class="contact-item">
            <img class="item-icon" src="../assets/images/price/duigou-icon.png" />
            <h3 class="item-text">{{ $t('package.co2') }}</h3>
          </div>
          <div class="contact-item">
            <img class="item-icon" src="../assets/images/price/duigou-icon.png" />
            <h3 class="item-text">{{ $t('package.co3') }}</h3>
          </div>
        </div>
        <div class="contact-group-right">
          <div class="contact-item">
            <img class="item-icon" src="../assets/images/price/duigou-icon.png" />
            <h3 class="item-text">{{ $t('package.co4') }}</h3>
          </div>
          <div class="contact-item">
            <img class="item-icon" src="../assets/images/price/duigou-icon.png" />
            <h3 class="item-text">{{ $t('package.co5') }}</h3>
          </div>
          <div class="contact-item">
            <img class="item-icon" src="../assets/images/price/duigou-icon.png" />
            <h3 class="item-text">{{ $t('package.co6') }}</h3>
          </div>
        </div>
      </div>
    </section>
    <!-- <section>
      <div class="container">
        <div class="title">
          <h2>{{ $t('common.price') }}</h2>
        </div>
        <div class="price-switch">
          <div class="switch-wrapper">
            <span :class="{ active: !discount }">{{ $t('package.monthly') }}</span>
            <a-switch v-model="discount"></a-switch>
            <span :class="{ active: discount }">{{ $t('package.annual') }}</span>
            <img src="../assets/images/icons/discount.svg" alt="save 20%" class="discount" />
          </div>
        </div>
        <div class="price-menu">
          <div class="price-item" v-for="(item, index) in packages" :key="index" :class="{ active: item.name === 'ENTERPRISE' }">
            <h4>{{ $t(`package.${item.name}`) }}</h4>
            <div class="price">
              <span class="unit">$</span>
              <span class="num">{{ discount ? item[priceLocaleMap[locale]][1] : item[priceLocaleMap[locale]][0] }}</span>
              <span class="unit-time">/{{ $t('time.month', { num: '' }) }}</span>
            </div>
            <ul>
              <li>{{ $t('package.callsPerMonth', [item.request]) }}</li>
              <li>{{ $t('package.qps', { num: item.qps }) }}</li>
              <li v-if="item.ws">{{ $t('package.ws', { num: item.ws }) }}</li>
              <li v-if="item.wh">Webhook Support (Beta)</li>
              <li v-if="item.isSupportRest">Rest Endpoint</li>
              <li v-if="item.support === 'email'">{{ $t('package.emailSupport') }}</li>
              <li v-if="item.custom">{{ $t('package.customFunctions') }}</li>
              <li v-if="item.support === 'email,phone'">{{ $t('package.phoneAndEmailSupport') }}</li>
            </ul>
            <div class="purchase">
              <router-link :to="{ name: 'order', query: { package: item.name, duration: discount ? 12 : 3 } }" class="purchase-link">{{
                item.name == 'FREE' ? $t('common.freeApiKey') : $t('package.subscribe')
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section
      class="business-cooperation"
      v-observe-visibility="{
        callback: isVisible => (visible = isVisible),
        intersection,
      }"
    >
      <div class="container">
        <div class="central">
          <h4>{{ $t('package.cooperation') }}</h4>
          <p>{{ $t('package.deepCooperation') }}</p>
          <router-link :to="{ name: 'contactus' }" class="button">{{ $t('package.contactus') }}</router-link>
        </div>
        <div class="services" :class="{ active: visible }">
          <div class="services-item"><i class="icon-check"></i>{{ $t('package.co1') }}</div>
          <div class="services-item">
            {{ $t('package.co2') }}
            <i class="icon-check"></i>
          </div>
          <div class="services-item"><i class="icon-check"></i>{{ $t('package.co3') }}</div>
          <div class="services-item">
            {{ $t('package.co4') }}
            <i class="icon-check"></i>
          </div>
          <div class="services-item"><i class="icon-check"></i>{{ $t('package.co5') }}</div>
          <div class="services-item">
            {{ $t('package.co6') }}
            <i class="icon-check"></i>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Switch from 'ant-design-vue/lib/switch';
import observeMixin from '../mixins/observe';
// import { on, off } from 'utils/dom';
export default {
  name: 'pricing',
  components: {
    [Switch.name]: Switch,
  },
  mixins: [observeMixin],
  data() {
    return {
      discount: true,
      visible: false,
      currencyMap: {
        CNY: '¥',
        USD: '$',
      },
      priceLocaleMap: {
        zh_CN: 'CNYPrice',
        en_US: 'USDPrice',
      },
    };
  },
  computed: {
    ...mapGetters(['packages', 'userInfo', 'locale']),
    currency() {
      return this.locale === 'zh_CN' ? 'CNY' : 'USD';
    },
  },
  mounted() {
    // on(document, 'scroll', this.handleScroll);
  },
  beforeDestroy() {
    // off(document, 'scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let containerHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let eleOffsetTop = this.$refs.container.offsetTop;
      if (scrollTop + containerHeight >= eleOffsetTop) {
        this.$refs.container.querySelector('.services').classList.add('active');
        off(document, 'scroll', this.handleScroll);
      }
    },
    // handleAlert() {
    //   this.$warning({
    //     title: '当前账号的体验版已过期，请升级套餐。',
    //     centered: true,
    //     onOk() {},
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
.main {
  background-color: rgb(247, 247, 248);
  background-image: url('../assets/images/dark-banner.png');
  background-size: 100% 747px;
  background-repeat: no-repeat;
  margin-top: -80px;
}
.container {
  width: @container;
  margin: 50px auto;
  position: relative;
}
.title {
  text-align: center;
  color: #4c4c4c;
  font-size: 30px;
  margin-bottom: 20px;
}
.price-switch {
  text-align: center;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #f1f1f1;
    top: 11px;
    left: 0;
  }
  .switch-wrapper {
    background-color: #fff;
    margin-bottom: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 22;
    padding: 0 20px;
  }
  .discount {
    position: absolute;
    right: -63px;
    top: -28px;
    width: 78px;
  }
  .ant-switch {
    margin: 0 5px;
  }
  span {
    color: #999;
    transition: color @transition-time ease-in-out;
    &.active {
      color: #222;
    }
  }
}
.price-menu {
  display: flex;
}
.price-item {
  width: @container / 5;
  vertical-align: top;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #d3d1d0;
  background: #fff;
  &:not(:last-of-type) {
    border-right: none;
  }
  h4 {
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    padding: 10px 0;
    background-color: #f7f7f7;
  }
  .price {
    text-align: center;
    padding: 20px 0;
    line-height: 1;
    span {
      display: inline-block;
    }
    .unit {
      font-size: 24px;
      vertical-align: top;
      margin-right: 5px;
      position: relative;
      top: 14px;
    }
    .num {
      font-size: 64px;
      font-weight: 500;
    }
    .unit-time {
      color: #999;
      margin-left: 10px;
    }
  }
  ul {
    flex: 1;
  }
  li {
    line-height: 45px;
    color: #666;
    text-align: center;
    &:nth-child(odd) {
      background: #fbf9f8;
    }
  }
  .purchase {
    text-align: center;
    margin: 20px 0;
  }
  .purchase-link {
    display: inline-flex;
    width: 120px;
    height: 30px;
    font-size: 14px;
    line-height: 29px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid mix(#fff, @red-color, 50%);
    color: mix(#fff, @red-color, 30%);
    &:hover {
      background-color: mix(#fff, @red-color, 90%);
    }
  }
  &.active {
    margin: -10px;
    border-right: 1px solid #d3d1d0;
    position: relative;
    z-index: 222;
    border-color: fade(mix(#000, @primary-color, 10%), 30%);
    background: mix(#fff, @primary-color, 90%);
    color: mix(#666, @primary-color, 25%);
    h4 {
      // color: mix(#222, @primary-color, 25%);
      background: mix(#fff, @primary-color, 80%);
    }
    li {
      color: mix(#222, @primary-color, 25%);
      &:nth-child(odd) {
        background: mix(#fff, @primary-color, 85%);
      }
    }
    .unit-time {
      color: mix(#666, @primary-color, 40%);
    }
    .purchase {
      position: relative;
      top: -10px;
    }
    .purchase-link {
      border-color: @primary-color;
      background-color: @primary-color;
      color: @dark-color;
      &:hover {
        border-color: mix(#fff, @dark-color, 10%);
        background-color: mix(#fff, @dark-color, 10%);
        color: #fff;
      }
    }
  }
}
.begin-anim(@direction) {
  & when (@direction = 'left') {
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  & when (@direction = 'right') {
    right: 50%;
    top: 50%;
    opacity: 0;
    transform: translateX(50%) translateY(-50%);
  }
}
@end-anim: {
  transform: translateX(0) translateY(0);
  opacity: 1;
};
.business-cooperation {
  margin: 50px auto;
  background: #f6f8f9;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  .central {
    text-align: center;
    position: relative;
    z-index: 333;
  }
  h4 {
    font-size: 50px;
    margin-bottom: 20px;
  }
  p {
    width: 400px;
    text-align: center;
    font-size: 20px;
    line-height: 1.8;
    margin: 0 auto 30px;
  }
  .services {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    &.active {
      .services-item {
        @moves: move-1, move-2, move-3, move-4, move-5, move-6;
        // @moves: move-4;
        each(@moves, {
                    &:nth-of-type(@{index}) {
                        animation: @value ease-out 0.5s forwards;
                    }
                });
      }
    }
  }
  .services-item {
    position: absolute;
    font-size: 18px;
    color: #33335d;
    font-weight: 700;
    display: inline-block;

    &:nth-of-type(odd) {
      .begin-anim('left');
      i {
        margin-right: 5px;
      }
    }
    &:nth-of-type(even) {
      .begin-anim('right');
      i {
        margin-left: 5px;
      }
    }
  }
}

@keyframes move-1 {
  from {
    .begin-anim('left');
  }
  to {
    left: 50px;
    top: 0;
    @end-anim();
  }
}
@keyframes move-2 {
  from {
    .begin-anim('right');
  }
  to {
    left: unset;
    right: 50px;
    top: 0;
    @end-anim();
  }
}
@keyframes move-3 {
  from {
    .begin-anim('left');
  }
  to {
    left: 50px;
    top: 50%;
    @end-anim();
    transform: translateY(-50%);
  }
}
@keyframes move-4 {
  from {
    .begin-anim('right');
  }
  to {
    left: unset;
    right: 50px;
    top: 50%;
    @end-anim();
    transform: translateY(-50%);
  }
}
@keyframes move-5 {
  from {
    .begin-anim('left');
  }
  to {
    left: 50px;
    top: unset;
    bottom: 0;
    @end-anim();
  }
}
@keyframes move-6 {
  from {
    .begin-anim('right');
  }
  to {
    left: unset;
    right: 50px;
    top: unset;
    bottom: 0;
    @end-anim();
  }
}
.price-block {
  width: @container;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  .tittle {
    margin-top: 135px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .price-content {
    margin-top: 60px;
    margin-bottom: 17px;
    width: @container;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .switch-group {
      align-self: flex-end;
      margin-top: 38px;
      margin-right: 39px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .month {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1e2133;
        margin-right: 10px;
      }
      .switch {
        margin-right: 10px;
      }
      .ant-switch-checked {
        background-color: rgb(22, 15, 79) !important;
      }
      .ant-switch-checked::after {
        background-color: #ffa800;
      }
      .year {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1e2133;
      }
      .tip {
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: #1e2133;
      }
    }
    .line {
      position: absolute;
      top: 415px;
      height: 1px;
      width: calc(@container - 40px);
      background-color: #ff7225;
      z-index: 3;
    }
    .package-group {
      margin-top: 46px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .package-item {
        width: calc((@container - 40px) / 5);
        background: #ffffff;
        border: 1px solid #e0dfe3;
        border-radius: 6px;
        margin-bottom: 32px;
        z-index: 1;
        position: relative;
        .recommended {
          width: calc((@container - 40px) / 5);
          position: absolute;
          top: -24px;
          left: -1px;
        }
        .package-head {
          background-color: #f3f3f8;
          height: 308px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          .package-name {
            margin-top: 41px;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1e2133;
          }
          .price {
            margin-top: 26px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .currency-icon {
              align-self: flex-start;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #1e2133;
            }
            .price-num {
              margin-left: 7px;
              font-size: 50px;
              font-family: Arial;
              // font-weight: bold;
              color: #1e2133;
            }
            .unit-time {
              margin-left: 6px;
              margin-bottom: 8px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #1e2133;
            }
          }
          .save-money {
            position: absolute;
            top: 150px;
            color: rgb(251, 106, 46);
          }
          .buy-btn {
            margin-top: 42px;
            width: 140px;
            height: 42px;
            background: #ffa800;
            border-radius: 2px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            // font-weight: bold;
            color: #ffffff;
            line-height: 42px;
            cursor: pointer;
          }
          .tip {
            margin-top: 22px;
            width: 146px;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #71727a;
            line-height: 18px;
          }
        }
        .package-foot {
          height: 542px;
          .package-content {
            margin-top: 5px;
            .content-item {
              margin-top: 38px;
              display: flex;
              flex-direction: column;
              align-items: center;
              .item-tittle {
                margin-top: 13px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #424452;
              }
              .item-value {
                text-align: center;
                height: 24px;
                line-height: 24px;
                font-size: 20px;
                font-family: Arial;
                font-weight: bold;
                color: #1e2133;
              }
            }
          }
        }
      }
      .active {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.29);
        z-index: 2;
      }
    }
  }
}
.custom-block {
  display: flex;
  flex-direction: row;
  .left-blcok {
    height: 284px;
    background: #efeff4;
    width: 40vw;
    position: relative;
    .left-box {
      position: absolute;
      right: 0;
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tittle {
        margin-top: 41px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffa800;
      }
      .tip {
        margin-top: 44px;
        width: 228px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1e2133;
        line-height: 26px;
      }
      .contact {
        margin-top: 48px;
        width: 120px;
        height: 40px;
        border: 1px solid #ffa800;
        border-radius: 2px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: #ffa800;
        line-height: 40px;
        text-align: center;
      }
    }
  }
  .right-block {
    height: 284px;
    background: #e7e7ed;
    width: 60vw;
    display: flex;
    flex-direction: row;
    .contact-group-left {
      display: flex;
      flex-direction: column;
      margin-top: 73px;
      margin-left: 102px;
    }
    .contact-group-right {
      display: flex;
      flex-direction: column;
      margin-top: 73px;
      margin-left: 102px;
    }
    .contact-item {
      display: flex;
      flex-direction: row;
      width: 200px;
      margin-bottom: 43px;
      .item-icon {
        width: 17px;
        height: 17px;
      }
      .item-text {
        margin-left: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1e2133;
      }
    }
  }
}
</style>
